// For socket events
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import { useScreenshot } from 'use-react-screenshot';
import { groupBy } from '../utils/helpers';
import { AdProviders } from '../types';
import { socket } from '../socket';
import { useDeviceId } from './useDeviceId';
import { queues } from '../config';

type UseSocketEventsReturnType = {
  showUuid: boolean
};
export const useSocketEvents = (): UseSocketEventsReturnType => {
  const history = useHistory();
  const deviceId = useDeviceId();
  const [image, takeScreenshot] = useScreenshot();
  const [showUuid, setShowUuid] = useState(true);

  const getImage = () => {
    const root = document.getElementsByClassName('app')[0];
    takeScreenshot(root, { useCORS: true });
  };

  useEffect(() => {
    socket.on('connect', (): void => {
      // setSocketDisconnected(false);
      console.log('Connected to server with socket id: ', socket.id);
      socket.emit('streets_id', deviceId);
      console.log('done emitting id');
    });

    socket.on('refresh', (): void => {
      socket.emit('refresh_confirm');
      window.location.reload();
    });

    socket.on('screenshot', (): void => {
      getImage();
    });

    socket.on('show_uuid', () => {
      console.log('pinged');
      setShowUuid(true);
    });

    socket.on('debug', ({ withScreenChange = true }: { withScreenChange: boolean }): void => {
      const lastRefresh = localStorage.getItem('lastRefresh');
      const debugQueues = {};
      const providerQueue = groupBy(queues.current?.providerQueue || [], 'type');
      AdProviders.forEach((provider) => { // @ts-ignore
        debugQueues[provider] = providerQueue[provider]?.length || 0;
      });

      const debugData = {
        lastRefresh, queues: debugQueues, deviceId, appVersion: window.DeviceApi.getAppVersion(),
      };
      socket.emit('debug_data', debugData);

      if (!withScreenChange) return;

      if (history.location.pathname.includes('debug')) {
        history.push('/home');
      } else {
        history.push('/debug');
      }
    });

    socket.on('disconnect', (reason: string): void => {
      console.log('Disconnected from server, reason: ', reason);
      // setSocketDisconnected(true);
      // if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect();
      // }
      // else the socket will automatically try to reconnect
      socket.emit('streets_id', deviceId);
    });

    socket.on('error', (err: string): void => {
      console.log('An error occured, firing from device ', err);
    });

    socket.on('sockErr', (): void => {
      console.error(
        'An error occurred while connecting to the server. Please refresh your page and try again. If you have this site open in multiple tabs, please close all other tabs before refreshing.',
      );
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('accountNeedsDeviceInfo');
      socket.off('error');
      socket.off('sockErr');
      socket.off('debug');
      socket.off('refresh');
      socket.off('screenshot');
      socket.off('show_uuid');
    };
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if (image) {
      socket.emit('screenshot_data', image);
    }
  }, [image]);

  useEffect(() => {
    let uuidTimeout: NodeJS.Timeout;
    if (showUuid && deviceId) {
      uuidTimeout = setTimeout(() => {
        setShowUuid(false);
      }, 15 * 1000);
    }

    return () => clearTimeout(uuidTimeout);
  }, [showUuid, deviceId]);

  return { showUuid };
};
