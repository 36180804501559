import { io } from 'socket.io-client';

declare const process: {
  env: {
    REACT_APP_SERVER_URL: string;
  }
};

// Create websocket connection to Portlserver-env
const wsServer = process.env.REACT_APP_SERVER_URL.indexOf('localhost') >= 0 ? 'http://localhost:5000/streets' : `${process.env.REACT_APP_SERVER_URL}:5000/streets`;

export const socket = io(wsServer, {
  forceNew: true,
  transports: ['websocket'],
  reconnection: true,
});

export default socket;
