import axios from 'axios';
import { axiosConfig, streetsUrl } from '../config';
import {
  Device,
} from '../types';

// Cache
// const CACHE_NAME = 'flying-monkeys';
// 50% of storage quota  // Decided 50% because the size of a cache vary a lot
// const CACHE_MAX_PERC = 0.5;
// 538mb used to cache 9 url
// const MAX_NUMB_CACHE = 60;

type GetDeviceInfoProps = {
  deviceId: string
};

// Need to find a return type for data
export const getDeviceInfoRequest = async ({ deviceId }: GetDeviceInfoProps): Promise<Device> => {
  try {
    const { data } = await axios.get(`${streetsUrl}/device/${deviceId}`, { ...axiosConfig, timeout: 5000 });
    return data;
  } catch (error) {
    return {};
  }
};

// const evictCache = async (cache: Cache): Promise<void> => {
//   let isCacheFull = false;
//   const cacheArray = await cache.keys();

//   if (navigator.storage && navigator.storage.estimate) {
//     const { quota, usage } = await navigator.storage.estimate();
//     if (quota && usage) {
//       isCacheFull = usage > quota * CACHE_MAX_PERC;
//     }
//   } else {
//     isCacheFull = cacheArray.length >= MAX_NUMB_CACHE;
//   }

//   if (isCacheFull) {
//     const deleteResponse = await cache.delete(cacheArray[0].url);
//     console.log('Delete cache item: ', cacheArray[0].url, deleteResponse);
//     await evictCache(cache);
//   }
// };

interface ZendeskInput {
  email?: string;
  subject: string;
  data: any;
}

export const createZendeskTicket = async ({
  subject,
  data,
  email,
}: ZendeskInput): Promise<void> => {
  const env = process.env.NODE_ENV;
  if (!env || env === 'development') {
    // only localhost has it undefined;
    console.warn('Disabling zendesk tickets for localhost...');
    return;
  }
  const fullSubject = `[${env}] ${subject}`;
  let stringBody: string;

  try {
    if (typeof data === 'string') {
      stringBody = data;
    } else if (data instanceof Error) {
      stringBody = data.toString();
    } else if (data.error instanceof Error) {
      stringBody = data.error.toString();
    } else {
      stringBody = JSON.stringify(data);
    }
  } catch (e) {
    stringBody = data;
  }
  try {
    const body = {
      request: {
        requester: {
          name: 'Automated Dev. Error',
          email: email || 'developer@portlmedia.com',
        },
        subject: fullSubject,
        comment: {
          body: `${stringBody} --- ${window.location}`,
        },
      },
    };
    await axios.post('https://portlmedia.zendesk.com/api/v2/requests.json', body, {
      withCredentials: false,
    });
  } catch (error) {
    console.warn('Error creating Zendesk ticket: ', error);
  }
};
