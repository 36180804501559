import axios from 'axios';

import { axiosConfig, streetsUrl } from '../../config';
// eslint-disable-next-line import/no-cycle
import { AdType } from '../../types';
// eslint-disable-next-line import/no-cycle
import { IAdLoop } from './requests';
import { NotificationType } from '../../utils/helpers';

type GetHivestackUUIDProps = {
  deviceId: string;
  country: string;
};

export const getHiveStackUUID = async ({ deviceId, country }: GetHivestackUUIDProps): Promise<string | null> => {
  const HIVESTACK_REFETCH_COOLDOWN = 1000 * 20;
  // Check if exists in localStorage:
  const refetchTimestamp = localStorage.getItem(`HivestackUuid${deviceId}-Refetch-Timestamp`);
  if (refetchTimestamp) {
    if (new Date().getTime() < Number(refetchTimestamp)) return null;
    localStorage.removeItem(`HivestackUuid${deviceId}-Refetch-Timestamp`);
  }

  const uuid = localStorage.getItem(`HivestackUuid${deviceId}`);
  if (uuid) {
    return uuid;
  }

  // Get from the server and store in localStorage:
  try {
    const { data } = await axios.get(
      `${streetsUrl}/uuid/${country}/${deviceId}`,
      axiosConfig,
    );

    if (data.uuid) {
      localStorage.setItem(`HivestackUuid${deviceId}`, data.uuid);
    }
    return data.uuid;
  } catch (error) {
    localStorage.setItem(`HivestackUuid${deviceId}-Refetch-Timestamp`, String(new Date().getTime() + HIVESTACK_REFETCH_COOLDOWN));
    console.error(
      'Error retrieving Hivestack UUID from Portl Server: ',
      error.response,
    );
    throw error;
  }
};

type GetVastAdProps = {
  deviceId: string;
  adType: AdType;
  count?: number;
  data?: Record<string, string | number | undefined>
};

export const getProviderAdsRequest = async ({
  deviceId, adType, count, data,
}: GetVastAdProps): Promise<IAdLoop[]> => {
  const response = await axios.post(`${streetsUrl}/ads`, {
    deviceId, adType, count: count || 1, data,
  },
  axiosConfig);
  return response.data;
};

type ProviderLogCount = Partial<Record<AdType, number>>;

export type Log = {
  pops: ProviderLogCount
  failedPops: ProviderLogCount
  adsQueued: ProviderLogCount
  adsRequested: ProviderLogCount
  latestAdRequest?: number | null
};

export const registerLog = (log: Log): void => {
  console.log('registering log...');
  axios.post(`${streetsUrl}/logs`, { deviceId: window.DeviceApi.getDeviceId(), ...log }, axiosConfig).catch((err) => console.log(`Error registering log: ${err}`));
};

export type DirectAdLogEntry = {
  cmsAdId: string
  playCount: number
};

export type DirectAdLogs = DirectAdLogEntry[];

export const registerDirectAdLog = (directAdLogs: DirectAdLogs): void => {
  console.log('registering direct ad log...');
  axios.post(`${streetsUrl}/direct-ad-logs`, { deviceId: window.DeviceApi.getDeviceId(), directAdLogs }, axiosConfig).catch((err) => console.log(`Error registering direct ad log: ${err}`));
};

export const sendQueueCapReachedNotification = (data: Record<string, unknown>, cooldown: number): void => {
  const id = window.DeviceApi.getDeviceId();
  const cooldownTimestamp = Number(localStorage.getItem(`QueueNotificationTimestamp-${id}`)) || 0;
  const now = new Date().getTime();

  const timePassed = now - cooldownTimestamp;

  if (timePassed < cooldown * 60 * 1000) {
    return;
  }

  const setCooldown = () => {
    localStorage.setItem(`QueueNotificationTimestamp-${id}`, now.toString());
  };

  console.log('sending queue cap reached notification...');
  axios.put(`${streetsUrl}/send-email-notification/${id}`, { data, type: NotificationType.QueueCap }, axiosConfig).catch((err) => {
    console.log(`Error sending queue cap reached notification: ${err}`);
  }).finally(setCooldown);
};
