import bcrypt from 'bcryptjs';
import { AdsQueue } from './views/AdLoop/requests';

type QueueType = {
  current: AdsQueue | null
};

const serverUrl = process.env.REACT_APP_SERVER_URL;
const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const businessUrl = process.env.REACT_APP_BUSINESS_URL;
const cdnURL = process.env.REACT_APP_CDN_URL;
const streetsApiKey = process.env.REACT_APP_STREETS_API_KEY;

const axiosConfig = {
  headers: {
    Authorization: `apiKey ${bcrypt.hashSync(streetsApiKey || '', 10)}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 60 * 1000,
};

const streetsUrl = `${serverUrl}/streets`;

const isProduction = process.env.REACT_APP_ENV === 'production';

const queues: QueueType = { current: null };
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const handleQueues = (newValue: any): void => {
  queues.current = newValue;
};

export {
  serverUrl, strapiUrl, businessUrl, streetsUrl, isProduction, cdnURL, streetsApiKey, axiosConfig, queues, handleQueues,
};
